// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import { CompanyResponse, OrganizationSettings, OrganizationsPerCountryResponse, StatisticsData } from '../models';

export interface GetCompanyDataUsingGETRequest {
  deviceType: GetCompanyDataUsingGETDeviceTypeEnum;
  uuid?: string;
}

export interface StoreOrganizationLanguageUsingPUTRequest {
  body: string;
}

export interface StoreOrganizationSettingsUsingPUTRequest {
  organizationSettings: OrganizationSettings;
}

/**
 * no description
 */
export class OrganizationControllerApi extends BaseAPI {
  /**
   * getCompanyData
   * Gets the company information for specific device.
   */
  getCompanyDataUsingGET = (requestParameters: GetCompanyDataUsingGETRequest): Observable<CompanyResponse> => {
    throwIfRequired(requestParameters, 'deviceType', 'getCompanyDataUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.deviceType && { deviceType: requestParameters.deviceType }),
      ...(requestParameters.uuid && { uuid: requestParameters.uuid })
    };

    return this.request<CompanyResponse>({
      path: '/company/info',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getCountryStats
   * Getting stats per country.
   */
  getCountryStatsUsingGET = (): Observable<OrganizationsPerCountryResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<OrganizationsPerCountryResponse>({
      path: '/support/countries/stats',
      method: 'GET',
      headers
    });
  };

  /**
   * getOrganizationSettings
   * Gets the Organization settings for the user specified in the UserContext.
   */
  getOrganizationSettingsUsingGET = (): Observable<OrganizationSettings> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<OrganizationSettings>({
      path: '/organization/settings',
      method: 'GET',
      headers
    });
  };

  /**
   * getStatisticsData
   * Gets statistics data
   */
  getStatisticsDataUsingGET = (): Observable<StatisticsData> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<StatisticsData>({
      path: '/support/statistics',
      method: 'GET',
      headers
    });
  };

  /**
   * storeOrganizationLanguage
   * Stores the language for an organization.
   */
  storeOrganizationLanguageUsingPUT = (
    requestParameters: StoreOrganizationLanguageUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'body', 'storeOrganizationLanguageUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/language',
      method: 'PUT',
      headers,
      body: requestParameters.body as any
    });
  };

  /**
   * storeOrganizationSettings
   * Stores the settings for the organization.
   */
  storeOrganizationSettingsUsingPUT = (
    requestParameters: StoreOrganizationSettingsUsingPUTRequest
  ): Observable<void> => {
    throwIfRequired(requestParameters, 'organizationSettings', 'storeOrganizationSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/admin/organization/settings',
      method: 'PUT',
      headers,
      body: requestParameters.organizationSettings
    });
  };
}

/**
 * @export
 * @enum {string}
 */
export enum GetCompanyDataUsingGETDeviceTypeEnum {
  GATEWAY = 'GATEWAY',
  APPLIANCE = 'APPLIANCE'
}

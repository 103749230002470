<template>
  <svg
    width="20px"
    height="22px"
    viewBox="0 0 20 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-548.000000, -607.000000)" style="fill: var(--v-secondary-base)">
        <g transform="translate(520.000000, 495.000000)">
          <g transform="translate(16.000000, 103.000000)">
            <g transform="translate(8.000000, 6.000000)">
              <g transform="translate(2.000000, 2.000000)">
                <path
                  d="M20,17 C21.1045695,17 22,17.8954305 22,19 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,19 C2,17.8954305 2.8954305,17 4,17 L20,17 Z M20,19 L4,19 L4,21 L20,21 L20,19 Z M12,1 C12.5522847,1 13,1.44771525 13,2 L13,2 L13,11.3986797 L15.3128932,9.08578644 C15.7034175,8.69526215 16.3365825,8.69526215 16.7271068,9.08578644 C17.1176311,9.47631073 17.1176311,10.1094757 16.7271068,10.5 L16.7271068,10.5 L12.02,15.2071068 L7.31289322,10.5 C6.92236893,10.1094757 6.92236893,9.47631073 7.31289322,9.08578644 C7.70341751,8.69526215 8.33658249,8.69526215 8.72710678,9.08578644 L8.72710678,9.08578644 L11,11.3586797 L11,2 C11,1.44771525 11.4477153,1 12,1 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<template>
  <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(7.000000, 8.000000)" fill-rule="nonzero">
        <path
          d="M21.5285749,9.99481078 L21.5285749,58.1489764 L29.5285749,58.1489764 L29.5285749,5.14897641 L21.5285749,9.99481078 Z M33.3892568,58.1489764 L42.3892568,58.1489764 L42.3892568,9.10735277 L33.3892568,5.14897641 L33.3892568,58.1489764 Z M56.1452866,34.1489764 L56.1452866,58.1489764 L62.1452866,58.1489764 L62.1452866,36.4572924 L56.1452866,34.1489764 Z M52.0220751,34.6110834 L46.676396,37.2590399 L46.676396,58.1489764 L52.0220751,58.1489764 L52.0220751,34.6110834 Z M13.0228109,35.1489764 L13.0228109,58.1489764 L18.3231134,58.1489764 L18.3231134,37.6125235 L13.0228109,35.1489764 Z M9.1676069,58.1489764 L9.1676069,35.36123 L3.85553103,36.7590881 L3.85553103,58.1487315 L9.1676069,58.1489764 Z M0,33.7007367 L11.77091,29.8388682 L18.3228681,33.0851665 L18.3228681,7.32086877 L30.6726351,0 L46.243725,5.75863163 L46.243725,33.3004813 L53.22909,29.8391948 L66,33.7010633 L66,62 L0,62 L0,33.7007367 Z"
          style="fill: var(--v-neutral40-base)"
        ></path>
      </g>
    </g>
  </svg>
</template>

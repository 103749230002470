import './init';

// Global light dom styles.
import '@liebherr/patternlib/dist/patternlib/patternlib.css';
// Liebherr web fonts.
import '@liebherr/patternlib/www/assets/css/patternlib-fonts.css';

import { createApp } from 'vue';
import { createI18n, I18n } from 'vue-i18n';
import { createPinia } from 'pinia';

// Calls applyPolyfills and defineCustomElements from the main package internally.
import { PatternLibraryPlugin } from '@liebherr/patternlib-vue';

import App from '@/App.vue';

import PengUIfy from '@/plugins/vuetify';

import CubeLogger from '@/Logger';
import Formatters from '@/Formatters';

// Initialize i18n
export const i18n: I18n = createI18n({
  legacy: false,
  locale: 'en-US',
  fallbackLocale: 'en-US',
  globalInjection: true
});

// Import Vue Router
import router from '@/router/router';

import VueApexCharts from 'vue3-apexcharts';

import { useI18nStore } from '@/I18nStore';

import '@/directives';
import '@/assets/global.css';

import EnvironmentHandler from './EnvironmentHandler';

// Create Vue app
const app = createApp(App);

app.use(PengUIfy);

// Use other plugins and store
app.use(CubeLogger);
app.use(Formatters);
const pinia = createPinia();
app.use(pinia);
app.use(PatternLibraryPlugin);
app.use(i18n);
app.use(VueApexCharts);

const environmentUrl: string = window.location.origin + '/environment.json?date=' + new Date().getTime().toString();

const i18nStore = useI18nStore();
i18nStore.init();

window
  .fetch(environmentUrl)
  .then(response => response.json())
  .then(json => {
    console.log('Init env');
    EnvironmentHandler.init(json);

    // Only use the router now, not before to avoid early routing (before Vue instance is there
    app.use(router);

    app.mount('#app');
  });

export default i18n;

<template>
  <svg width="80" height="80" viewBox="0 0 80 80" version="1.1">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
      <path
        d="M46,27.6212443 L46,52.1747273 C50.7704793,54.817362 54,59.9028539 54,65.7426407 C54,72.1757533 50.0808996,77.6935109 44.5,80.0386123 L44.5,64.2426407 L32.5,64.2426407 L32.5,80.0386123 C26.9191004,77.6935109 23,72.1757533 23,65.7426407 C23,59.5034087 26.6864331,54.1251892 32,51.6672818 L32,28.1286899 C26.6864331,25.6707824 23,20.292563 23,14.053331 C23,7.62021835 26.9191004,2.10246069 32.5,-0.242640687 L32.5,15.553331 L44.5,15.553331 L44.5,-0.242640687 C50.0808996,2.10246069 54,7.62021835 54,14.053331 C54,19.8931178 50.7704793,24.9786096 46,27.6212443 Z"
        style="fill: var(--v-neutral40-base)"
        transform="translate(38.500000, 39.897986) rotate(-315.000000) translate(-38.500000, -39.897986) "
      ></path>
    </g>
  </svg>
</template>

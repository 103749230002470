<template>
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1285.000000, -809.000000)">
        <g transform="translate(1285.000000, 809.000000)">
          <path
            d="M58.1818182,67.00436 L58.1818182,70 C58.1818182,71.6568542 56.8386724,73 55.1818182,73 L24.8181818,73 C23.1613276,73 21.8181818,71.6568542 21.8181818,70 L21.8181818,67.00436 C20.185957,66.5003005 19,64.9795569 19,63.1818182 L19,12 C19,9.790861 20.790861,8 23,8 L57,8 C59.209139,8 61,9.790861 61,12 L61,63.1818182 C61,64.9795569 59.814043,66.5003005 58.1818182,67.00436 L58.1818182,67.00436 Z M21,28 L59,28 L59,12 C59,10.8954305 58.1045695,10 57,10 L23,10 C21.8954305,10 21,10.8954305 21,12 L21,28 Z M21,30 L21,63.1818182 C21,64.2863877 21.8954305,65.1818182 23,65.1818182 L57,65.1818182 C58.1045695,65.1818182 59,64.2863877 59,63.1818182 L59,30 L21,30 Z M24.5454545,14 C24.5454545,13.4477153 24.9931698,13 25.5454545,13 C26.0977393,13 26.5454545,13.4477153 26.5454545,14 L26.5454545,24 C26.5454545,24.5522847 26.0977393,25 25.5454545,25 C24.9931698,25 24.5454545,24.5522847 24.5454545,24 L24.5454545,14 Z M24.5454545,34 C24.5454545,33.4477153 24.9931698,33 25.5454545,33 C26.0977393,33 26.5454545,33.4477153 26.5454545,34 L26.5454545,61 C26.5454545,61.5522847 26.0977393,62 25.5454545,62 C24.9931698,62 24.5454545,61.5522847 24.5454545,61 L24.5454545,34 Z M23.8181818,70 C23.8181818,70.5522847 24.2658971,71 24.8181818,71 L55.1818182,71 C55.7341029,71 56.1818182,70.5522847 56.1818182,70 L56.1818182,67.2727273 L23.8181818,67.2727273 L23.8181818,70 Z"
            fill="#171E3D"
            fill-rule="nonzero"
          />
          <circle fill="#171E3D" cx="61" cy="61" r="17" />
          <path
            d="M61.9587402,63.5512695 L61.9997559,62.9257812 C62.0749512,62.2558594 62.3723145,61.6713867 62.8918457,61.1723633 L62.8918457,61.1723633 L63.7224121,60.3828125 C64.3718262,59.7539062 64.826416,59.1813965 65.0861816,58.6652832 C65.3459473,58.1491699 65.4758301,57.6005859 65.4758301,57.0195312 C65.4758301,55.7412109 65.0759277,54.751709 64.276123,54.0510254 C63.4763184,53.3503418 62.3518066,53 60.9025879,53 C59.467041,53 58.3339844,53.3691406 57.503418,54.1074219 C56.6728516,54.8457031 56.2507324,55.8676758 56.2370605,57.1733398 L56.2370605,57.1733398 L59.2106934,57.1733398 C59.2243652,56.6264648 59.3833008,56.1975098 59.6875,55.8864746 C59.9916992,55.5754395 60.3967285,55.4199219 60.9025879,55.4199219 C61.9689941,55.4199219 62.5021973,55.9975586 62.5021973,57.152832 C62.5021973,57.5356445 62.3996582,57.8996582 62.1945801,58.244873 C61.989502,58.5900879 61.5776367,59.0378418 60.9589844,59.5881348 C60.340332,60.1384277 59.9147949,60.6972656 59.682373,61.2646484 C59.4499512,61.8320312 59.3337402,62.5942383 59.3337402,63.5512695 L59.3337402,63.5512695 L61.9587402,63.5512695 Z M60.6975098,68.2578125 C61.1965332,68.2578125 61.5981445,68.1108398 61.9023438,67.8168945 C62.206543,67.5229492 62.3586426,67.1503906 62.3586426,66.6992188 C62.3586426,66.2412109 62.203125,65.8635254 61.8920898,65.5661621 C61.5810547,65.2687988 61.1828613,65.1201172 60.6975098,65.1201172 C60.2121582,65.1201172 59.8139648,65.2687988 59.5029297,65.5661621 C59.1918945,65.8635254 59.036377,66.2412109 59.036377,66.6992188 C59.036377,67.1503906 59.1884766,67.5229492 59.4926758,67.8168945 C59.796875,68.1108398 60.1984863,68.2578125 60.6975098,68.2578125 Z"
            fill="#FFFFFF"
            fill-rule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired } from '../runtime';
import { ContinuationAlarmToken, FilteredMergedAlarmsResponse } from '../models';

export interface FilterMergedAlarmsUsingPUTRequest {
  filter: FilterMergedAlarmsUsingPUTFilterEnum;
  continuationAlarmToken: ContinuationAlarmToken;
  gatewayId?: string;
  gatewaySerialNumber?: string;
  smartModuleSerialNumber?: string;
  smartModuleVfId?: string;
  virtualFridgeId?: string;
}

/**
 * no description
 */
export class AlarmControllerApi extends BaseAPI {
  /**
   * filterMergedAlarms
   * Gets list with all alarms related to device which we search by
   */
  filterMergedAlarmsUsingPUT = (
    requestParameters: FilterMergedAlarmsUsingPUTRequest
  ): Observable<FilteredMergedAlarmsResponse> => {
    throwIfRequired(requestParameters, 'filter', 'filterMergedAlarmsUsingPUT');
    throwIfRequired(requestParameters, 'continuationAlarmToken', 'filterMergedAlarmsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.filter && { filter: requestParameters.filter }),
      ...(requestParameters.gatewayId && { gatewayId: requestParameters.gatewayId }),
      ...(requestParameters.gatewaySerialNumber && { gatewaySerialNumber: requestParameters.gatewaySerialNumber }),
      ...(requestParameters.smartModuleSerialNumber && {
        smartModuleSerialNumber: requestParameters.smartModuleSerialNumber
      }),
      ...(requestParameters.smartModuleVfId && { smartModuleVfId: requestParameters.smartModuleVfId }),
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<FilteredMergedAlarmsResponse>({
      path: '/filteredmergedalarms',
      method: 'PUT',
      headers,
      query,
      body: requestParameters.continuationAlarmToken
    });
  };
}

/**
 * @export
 * @enum {string}
 */
export enum FilterMergedAlarmsUsingPUTFilterEnum {
  APPLIANCE = 'APPLIANCE',
  GATEWAY = 'GATEWAY',
  SMARTMODULE = 'SMART_MODULE',
  MERGED = 'MERGED'
}

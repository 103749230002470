<template>
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect id="path-1" x="0" y="0" width="12" height="24" />
      <rect id="path-3" x="0" y="0" width="12" height="24" />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1084.000000, -895.000000)">
        <g transform="translate(140.000000, 489.000000)">
          <g transform="translate(437.000000, 104.000000)">
            <g transform="translate(0.000000, 276.000000)">
              <g transform="translate(506.000000, 25.000000)">
                <g id="Combined-Shape">
                  <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1" />
                  </mask>
                  <path
                    d="M12,1 L11.9994661,3.171 L13.1213203,2.05025253 L14.5355339,3.46446609 L11.9994661,5.999 L11.998746,9.268 L14.8307261,7.63301222 L15.7581968,4.17037087 L17.6900485,4.68800896 L17.2798459,6.21901222 L19.160254,5.1339746 L20.160254,6.8660254 L18.2798459,7.95106303 L19.8113688,8.36224357 L19.2937307,10.2940952 L15.8307261,9.36506303 L12.999746,10.999 L15.83126,12.6340122 L19.2937307,11.7059048 L19.8113688,13.6377564 L18.2803798,14.0480122 L20.160254,15.1339746 L19.160254,16.8660254 L17.2803798,15.780063 L17.6900485,17.311991 L15.7581968,17.8296291 L14.83126,14.366063 L11.998746,12.731 L11.9994661,15.999 L14.5355339,18.5355339 L13.1213203,19.9497475 L11.9994661,18.828 L12,21 L10,21 L9.99946609,18.828 L8.87867966,19.9497475 L7.46446609,18.5355339 L9.99946609,15.999 L9.99874596,12.732 L7.17047205,14.365063 L6.24180316,17.8296291 L4.30995151,17.311991 L4.72048619,15.779563 L2.83974596,16.8660254 L1.83974596,15.1339746 L3.72048619,14.0475122 L2.18863116,13.6377564 L2.70626925,11.7059048 L6.17047205,12.6330122 L8.99974596,10.999 L6.17100596,9.36606303 L2.70626925,10.2940952 L2.18863116,8.36224357 L3.72102009,7.95156303 L1.83974596,6.8660254 L2.83974596,5.1339746 L4.72102009,6.21951222 L4.30995151,4.68800896 L6.24180316,4.17037087 L7.17100596,7.63401222 L9.99874596,9.267 L9.99946609,5.999 L7.46446609,3.46446609 L8.87867966,2.05025253 L9.99946609,3.171 L10,1 L12,1 Z"
                    style="fill: var(--v-secondary-base)"
                    mask="url(#mask-2)"
                  />
                </g>
                <g transform="translate(12.000000, 0.000000)">
                  <mask id="mask-4" fill="white">
                    <use xlink:href="#path-3" />
                  </mask>
                  <path
                    d="M4,5 C7.86599325,5 11,8.2235498 11,12.2 C11,14.7184185 8.89416667,18.0933394 4.6825,22.3247628 L4.6825,22.3247628 L4,23 C-0.666666667,18.4509668 -3,14.8509668 -3,12.2 C-3,8.2235498 0.134006751,5 4,5 Z M4,7 C1.25138163,7 -1,9.31570682 -1,12.2 C-1,13.9222849 0.637571634,16.646616 4,20.1606662 L4,20.1606662 L4.30092606,19.8433885 C7.46017139,16.4824252 9,13.8700944 9,12.2 C9,9.31570682 6.74861837,7 4,7 Z M3,9 C5.45389898,9 7,10.1890433 7,13 L7,13 L5,13 C5,11.4253122 4.44697078,11 3,11 L3,11 Z"
                    style="fill: var(--v-secondary-base)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

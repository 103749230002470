<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M12,12 C14.7614237,12 17,9.76142375 17,7 C17,4.23857625 14.7614237,2 12,2 C9.23857625,2 7,4.23857625 7,7 C7,9.76142375 9.23857625,12 12,12 Z M12,14 C8.13400675,14 5,10.8659932 5,7 C5,3.13400675 8.13400675,0 12,0 C15.8659932,0 19,3.13400675 19,7 C19,10.8659932 15.8659932,14 12,14 Z M1.99261352,23.1213194 C1.92561065,23.6695247 1.42688588,24.0596164 0.87868057,23.9926135 C0.330475262,23.9256106 -0.0596163889,23.4268859 0.0073864821,22.8786806 C0.572085587,18.2584152 4.71251339,16 12,16 C19.2874866,16 23.4279144,18.2584152 23.9926135,22.8786806 C24.0596164,23.4268859 23.6695247,23.9256106 23.1213194,23.9926135 C22.5731141,24.0596164 22.0743894,23.6695247 22.0073865,23.1213194 C21.5943078,19.7415848 18.4014023,18 12,18 C5.59859772,18 2.40569219,19.7415848 1.99261352,23.1213194 Z"
        style="fill: var(--v-bus_white-base)"
      />
    </g>
  </svg>
</template>

<template>
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="matrix(0.941176, 0, 0, 0.941176, -0.941168, -0.941169)"
      style
    >
      <path
        d="M33,5 C34.1045695,5 35,5.8954305 35,7 L35,29 C35,29.0845965 34.9947477,29.1679663 34.9845514,29.2498011 C35.0374724,29.5485283 34.9534701,29.8681983 34.7295372,30.1070601 C34.6775267,30.162538 34.6206795,30.2107641 34.560262,30.2516975 C34.1938401,30.7077364 33.631051,31 33,31 L3,31 C2.36894903,31 1.80615988,30.7077364 1.43960684,30.2511836 C1.37932049,30.2107641 1.32247335,30.162538 1.2704628,30.1070601 C1.04652987,29.8681983 0.962527606,29.5485283 1.01519602,29.2491093 C1.00525232,29.1679663 1,29.0845965 1,29 L1,7 C1,5.8954305 1.8954305,5 3,5 L33,5 Z M21.6049842,19.1737309 L19.3170092,21.1763852 C18.6073132,21.7973691 17.5682166,21.8338976 16.8197676,21.2859706 L16.6829908,21.1763852 L14.3929967,19.174 L3.91199675,29 L32.0863091,28.9997875 C27.7121615,24.8991015 24.2183865,21.6237493 21.6049842,19.1737309 Z M33,9.203 L23.1179967,17.85 L32.9999967,27.115 L33,9.203 Z M3,9.204 L3.00131702,27.113652 C7.09942124,23.2716785 10.3927807,20.1841534 12.8813955,17.8510766 L3,9.204 Z M32.48,7 L3.519,7 L18,19.6712318 L32.48,7 Z"
        style="fill: var(--v-secondary-base)"
      />
    </g>
  </svg>
</template>

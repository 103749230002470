<template>
  <v-dialog v-model="dialogShown" max-width="800px" persistent>
    <v-card>
      <v-container>
        <v-layout column align-center>
          <v-flex>
            <h3>{{ $t('biz.home.supportedbrowsers.title') }}</h3>
            <p class="bus_normaltext mt-3">{{ $t('biz.home.supportedbrowsers.description') }}</p>
          </v-flex>
          <v-layout mt-5 row wrap style="width: 100%">
            <v-flex xs3>
              <v-layout column align-center>
                <v-img :src="AssetHelper.chrome" class="browser-img" contain></v-img>
                <p class="browser-name">{{ $t('biz.home.supportedbrowsers.chrome') }}</p>
                <v-btn
                  target="_blank"
                  href="https://www.google.com/chrome/"
                  depressed
                  outline
                  color="primary"
                  v-track="'SupportBrowserDialog::DownloadChrome'"
                  >{{ $t('general.download') }}</v-btn
                >
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <v-layout column align-center>
                <v-img :src="AssetHelper.firefox" class="browser-img" contain></v-img>
                <p class="browser-name">{{ $t('biz.home.supportedbrowsers.firefox') }}</p>
                <v-btn
                  target="_blank"
                  href="https://www.mozilla.org"
                  depressed
                  outline
                  color="primary"
                  v-track="'SupportBrowserDialog::DownloadMozilla'"
                  >{{ $t('general.download') }}</v-btn
                >
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <v-layout column align-center>
                <v-img :src="AssetHelper.edge" class="browser-img" contain></v-img>
                <p class="browser-name">{{ $t('biz.home.supportedbrowsers.edge') }}</p>
                <v-btn
                  target="_blank"
                  href="https://www.microsoft.com/windows/microsoft-edge"
                  depressed
                  outline
                  color="primary"
                  v-track="'SupportBrowserDialog::DownloadMSEdge'"
                  >{{ $t('general.download') }}</v-btn
                >
              </v-layout>
            </v-flex>
            <v-flex xs3>
              <v-layout column align-center>
                <v-img :src="AssetHelper.safari" class="browser-img" contain></v-img>
                <p class="browser-name">{{ $t('biz.home.supportedbrowsers.safari') }}</p>
                <v-btn
                  target="_blank"
                  href="https://www.apple.com/safari/"
                  depressed
                  outline
                  color="primary"
                  v-track="'SupportBrowserDialog::DownloadSafari'"
                  >{{ $t('general.download') }}</v-btn
                >
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" src="./SupportedBrowsersDialog.ts"></script>
<style scoped src="./SupportedBrowsersDialog.css"></style>

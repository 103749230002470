// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import {
  ApplianceResponse,
  ApplianceState,
  AppliancesResponse,
  GatewayMeasureSiteResponse,
  MeasureSite,
  SmartModuleApplianceData,
  SmartModuleState
} from '../models';

export interface GetApplianceDataUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetApplianceDataWithSerialNumberUsingGETRequest {
  serialNumber: string;
}

export interface GetApplianceStateUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetMeasureSiteDataUsingGETRequest {
  virtualFridgeId: string;
}

export interface GetMeasureSitesOfGatewayUsingGETRequest {
  gatewayId: string;
}

export interface GetSmartModuleDataWithSerialNumberUsingGETRequest {
  serialNumber: string;
}

export interface GetZoneStateUsingGETRequest {
  virtualFridgeId: string;
}

/**
 * no description
 */
export class ApplianceControllerApi extends BaseAPI {
  /**
   * getApplianceData
   * Gets information about appliance.
   */
  getApplianceDataUsingGET = (requestParameters: GetApplianceDataUsingGETRequest): Observable<ApplianceResponse> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getApplianceDataUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<ApplianceResponse>({
      path: '/appliance/info',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getApplianceDataWithSerialNumber
   * Gets appliance data with serial number
   */
  getApplianceDataWithSerialNumberUsingGET = (
    requestParameters: GetApplianceDataWithSerialNumberUsingGETRequest
  ): Observable<AppliancesResponse> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getApplianceDataWithSerialNumberUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.serialNumber && { serialNumber: requestParameters.serialNumber })
    };

    return this.request<AppliancesResponse>({
      path: '/appliances/info',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getApplianceState
   * Gets appliance state by fridge ID.
   */
  getApplianceStateUsingGET = (requestParameters: GetApplianceStateUsingGETRequest): Observable<ApplianceState> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getApplianceStateUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<ApplianceState>({
      path: '/appliance/appliancestate',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getMeasureSiteData
   * Gets information about measure site.
   */
  getMeasureSiteDataUsingGET = (requestParameters: GetMeasureSiteDataUsingGETRequest): Observable<MeasureSite> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getMeasureSiteDataUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.virtualFridgeId && { virtualFridgeId: requestParameters.virtualFridgeId })
    };

    return this.request<MeasureSite>({
      path: '/measuresite/info',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getMeasureSitesOfGateway
   * Gets modbus and binder appliances attached to a gateway.
   */
  getMeasureSitesOfGatewayUsingGET = (
    requestParameters: GetMeasureSitesOfGatewayUsingGETRequest
  ): Observable<GatewayMeasureSiteResponse> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getMeasureSitesOfGatewayUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<GatewayMeasureSiteResponse>({
      path: '/connectables/{gatewayId}'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getSmartModuleDataWithSerialNumber
   * Gets smartmodule fridge data with serial number
   */
  getSmartModuleDataWithSerialNumberUsingGET = (
    requestParameters: GetSmartModuleDataWithSerialNumberUsingGETRequest
  ): Observable<SmartModuleApplianceData> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getSmartModuleDataWithSerialNumberUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.serialNumber && { serialNumber: requestParameters.serialNumber })
    };

    return this.request<SmartModuleApplianceData>({
      path: '/smartmodule/info',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getZoneState
   * Gets detailed zone state information.
   */
  getZoneStateUsingGET = (requestParameters: GetZoneStateUsingGETRequest): Observable<SmartModuleState> => {
    throwIfRequired(requestParameters, 'virtualFridgeId', 'getZoneStateUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<SmartModuleState>({
      path: '/smartmodule/zonestate/{virtualFridgeId}'.replace(
        '{virtualFridgeId}',
        encodeURI(requestParameters.virtualFridgeId)
      ),
      method: 'GET',
      headers
    });
  };
}

<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1140.000000, -474.000000)" style="fill: var(--v-secondary-base)">
        <g transform="translate(734.000000, 446.000000)">
          <g transform="translate(392.000000, 16.000000)">
            <g transform="translate(8.000000, 6.000000)">
              <g transform="translate(2.000000, 2.000000)">
                <g transform="translate(4.000000, 4.000000)">
                  <path
                    d="M8,6.58578644 L14.2928932,0.292893219 C14.6834175,-0.0976310729 15.3165825,-0.0976310729 15.7071068,0.292893219 C16.0976311,0.683417511 16.0976311,1.31658249 15.7071068,1.70710678 L9.41421356,8 L15.7071068,14.2928932 C16.0976311,14.6834175 16.0976311,15.3165825 15.7071068,15.7071068 C15.3165825,16.0976311 14.6834175,16.0976311 14.2928932,15.7071068 L8,9.41421356 L1.70710678,15.7071068 C1.31658249,16.0976311 0.683417511,16.0976311 0.292893219,15.7071068 C-0.0976310729,15.3165825 -0.0976310729,14.6834175 0.292893219,14.2928932 L6.58578644,8 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L8,6.58578644 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

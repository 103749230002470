// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfRequired, encodeURI } from '../runtime';
import { AccessCheckResponse, ColleagueResponse, SupportUser, UserInfoResponse, UserSettings } from '../models';

export interface AddSupportUserUsingPOSTRequest {
  supportUser: SupportUser;
}

export interface DeleteSupportUserUsingDELETERequest {
  upn: string;
}

export interface EditSupportUserUsingPUTRequest {
  supportUser: SupportUser;
}

export interface GetSupportUsersUsingGETRequest {
  queryString?: string;
}

export interface StoreUserSettingsUsingPUTRequest {
  userSettings: UserSettings;
}

/**
 * no description
 */
export class UserControllerApi extends BaseAPI {
  /**
   * addSupportUser
   * Creates a new SupportUser entry.
   */
  addSupportUserUsingPOST = (requestParameters: AddSupportUserUsingPOSTRequest): Observable<string> => {
    throwIfRequired(requestParameters, 'supportUser', 'addSupportUserUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<string>({
      path: '/user/support/create',
      method: 'POST',
      headers,
      body: requestParameters.supportUser
    });
  };

  /**
   * checkAccess
   * Checks whether the current user can access the application.
   */
  checkAccessUsingPOST = (): Observable<AccessCheckResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<AccessCheckResponse>({
      path: '/access/check',
      method: 'POST',
      headers
    });
  };

  /**
   * checkUser
   * Gets the user info about the currently logged-in user. Creates the user and the organization if needed.
   */
  checkUserUsingPOST = (): Observable<UserInfoResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<UserInfoResponse>({
      path: '/user/check',
      method: 'POST',
      headers
    });
  };

  /**
   * deleteSupportUser
   * Deletes a SupportUser entry.
   */
  deleteSupportUserUsingDELETE = (requestParameters: DeleteSupportUserUsingDELETERequest): Observable<string> => {
    throwIfRequired(requestParameters, 'upn', 'deleteSupportUserUsingDELETE');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<string>({
      path: '/user/support/delete/{upn}'.replace('{upn}', encodeURI(requestParameters.upn)),
      method: 'DELETE',
      headers
    });
  };

  /**
   * editSupportUser
   * Edits an existing SupportUser entry.
   */
  editSupportUserUsingPUT = (requestParameters: EditSupportUserUsingPUTRequest): Observable<string> => {
    throwIfRequired(requestParameters, 'supportUser', 'editSupportUserUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<string>({
      path: '/user/support/edit',
      method: 'PUT',
      headers,
      body: requestParameters.supportUser
    });
  };

  /**
   * getColleagues
   * Gets the colleagues information for a user
   */
  getColleaguesUsingGET = (): Observable<ColleagueResponse> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ColleagueResponse>({
      path: '/user/colleagues',
      method: 'GET',
      headers
    });
  };

  /**
   * getSupportUsers
   * Gets the list of support users with optionally applied query string.
   */
  getSupportUsersUsingGET = (requestParameters: GetSupportUsersUsingGETRequest): Observable<Array<SupportUser>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    const query: HttpQuery = {
      ...(requestParameters.queryString && { queryString: requestParameters.queryString })
    };

    return this.request<Array<SupportUser>>({
      path: '/user/support',
      method: 'GET',
      headers,
      query
    });
  };

  /**
   * getUserSettings
   * Gets the user settings for the user specified in the UserContext.
   */
  getUserSettingsUsingGET = (): Observable<UserSettings> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<UserSettings>({
      path: '/user/settings',
      method: 'GET',
      headers
    });
  };

  /**
   * storeUserSettings
   * Stores the UserContext for the user.
   */
  storeUserSettingsUsingPUT = (requestParameters: StoreUserSettingsUsingPUTRequest): Observable<void> => {
    throwIfRequired(requestParameters, 'userSettings', 'storeUserSettingsUsingPUT');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/user/settings',
      method: 'PUT',
      headers,
      body: requestParameters.userSettings
    });
  };
}

<template>
  <svg width="30px" height="30px" version="1.1" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1.774938, 0, 0, 1.774938, -6.435925, 4.350373)" style>
      <path
        style="fill: var(--v-secondary-base)"
        fill-rule="evenodd"
        stroke="none"
        stroke-width="1"
        d="M 12 6 C 13.668 6 15.177 6.68 16.264 7.779 L 15.198 8.834 C 14.382 8.01 13.251 7.5 12 7.5 C 10.779 7.5 9.671 7.987 8.86 8.777 L 7.813 7.702 C 8.894 6.649 10.371 6 12 6 Z M 12 3 C 14.502 3 16.765 4.021 18.396 5.668 L 17.33 6.724 C 15.971 5.351 14.085 4.5 12 4.5 C 9.964 4.5 8.118 5.311 6.766 6.628 L 5.719 5.554 C 7.341 3.973 9.557 3 12 3 Z M 12 9 C 12.834 9 13.588 9.34 14.132 9.889 L 12 12 L 9.906 9.851 C 10.447 9.324 11.186 9 12 9 Z"
      />
      <path
        style="fill: var(--v-secondary-base)"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        d="M 12 0 C 15.335 0 18.353 1.361 20.528 3.558 L 19.462 4.613 C 17.559 2.691 14.919 1.5 12 1.5 C 9.15 1.5 6.565 2.636 4.673 4.479 L 3.626 3.405 C 5.788 1.298 8.743 0 12 0 Z"
        opacity="0.1"
      />
    </g>
  </svg>
</template>

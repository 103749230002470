<template>
  <div id="app">
    <v-app>
      <v-content>
        <SupportedBrowsersDialog v-if="unsupportedBrowser" :dialogShown="unsupportedBrowser"></SupportedBrowsersDialog>
        <MaintenanceDialog
          v-else-if="maintenance"
          :dialogShown="maintenance"
          :data="maintenanceObject"
        ></MaintenanceDialog>
        <router-view v-else></router-view>
      </v-content>
    </v-app>
  </div>
</template>

<script lang="ts" src="./App.ts"></script>

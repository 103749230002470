<template>
  <svg width="80px" height="80px" version="1.1" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
    <path
      style="fill: var(--v-error-base)"
      fill-rule="nonzero"
      d="M 50.182 61.004 L 50.182 64 C 50.182 65.657 48.839 67 47.182 67 L 16.818 67 C 15.161 67 13.818 65.657 13.818 64 L 13.818 61.004 C 12.186 60.5 11 58.98 11 57.182 L 11 6 C 11 3.791 12.791 2 15 2 L 49 2 C 51.209 2 53 3.791 53 6 L 53 57.182 C 53 58.98 51.814 60.5 50.182 61.004 L 50.182 61.004 Z M 13 22 L 51 22 L 51 6 C 51 4.895 50.105 4 49 4 L 15 4 C 13.895 4 13 4.895 13 6 L 13 22 Z M 13 24 L 13 57.182 C 13 58.286 13.895 59.182 15 59.182 L 49 59.182 C 50.105 59.182 51 58.286 51 57.182 L 51 24 L 13 24 Z M 16.545 8 C 16.545 7.448 16.993 7 17.545 7 C 18.098 7 18.545 7.448 18.545 8 L 18.545 18 C 18.545 18.552 18.098 19 17.545 19 C 16.993 19 16.545 18.552 16.545 18 L 16.545 8 Z M 16.545 28 C 16.545 27.448 16.993 27 17.545 27 C 18.098 27 18.545 27.448 18.545 28 L 18.545 55 C 18.545 55.552 18.098 56 17.545 56 C 16.993 56 16.545 55.552 16.545 55 L 16.545 28 Z M 15.818 64 C 15.818 64.552 16.266 65 16.818 65 L 47.182 65 C 47.734 65 48.182 64.552 48.182 64 L 48.182 61.273 L 15.818 61.273 L 15.818 64 Z"
      stroke="none"
      stroke-width="1"
    />
    <circle
      cx="53"
      cy="60"
      r="17"
      style="fill: var(--v-error-base)"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="matrix(1, 0, 0, 1, 44.215633, 51.570637)"
    >
      <g transform="translate(-1140.000000, -474.000000)" style="fill: var(--v-bus_white-base)">
        <g transform="translate(734.000000, 446.000000)">
          <g transform="translate(392.000000, 16.000000)">
            <g transform="translate(8.000000, 6.000000)">
              <g transform="translate(2.000000, 2.000000)">
                <g transform="translate(4.000000, 4.000000)">
                  <path
                    d="M 8.784 7.016 L 15.077 0.723 C 15.468 0.332 16.101 0.332 16.491 0.723 C 16.882 1.113 16.882 1.747 16.491 2.137 L 10.199 8.43 L 16.491 14.723 C 16.882 15.113 16.882 15.747 16.491 16.137 C 16.101 16.528 15.468 16.528 15.077 16.137 L 8.784 9.844 L 2.491 16.137 C 2.101 16.528 1.468 16.528 1.077 16.137 C 0.687 15.747 0.687 15.113 1.077 14.723 L 7.37 8.43 L 1.077 2.137 C 0.687 1.747 0.687 1.113 1.077 0.723 C 1.468 0.332 2.101 0.332 2.491 0.723 L 8.784 7.016 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect style="fill: var(--v-secondary-base)" x="5" y="12" width="6" height="10" />
      <rect style="fill: var(--v-secondary-base)" x="13" y="4" width="6" height="18" />
    </g>
  </svg>
</template>

<template>
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
      style
      transform="matrix(0.9, 0, 0, 0.818182, -1.8, -0.818182)"
    >
      <path style="fill: var(--v-secondary-base)" fill-rule="nonzero" d="M5,4V6H19V4H5M5,14H9V20H15V14H19L12,7L5,14Z" />
    </g>
  </svg>
</template>

<!--<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
  <path d="M5,4V6H19V4H5M5,14H9V20H15V14H19L12,7L5,14Z" />
</svg>-->

// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface SupportUser
 */
export interface SupportUser {
  /**
   * @type {string}
   * @memberof SupportUser
   */
  upn?: string;
  /**
   * @type {Array<string>}
   * @memberof SupportUser
   */
  userAccessLevels?: Array<SupportUserUserAccessLevelsEnum>;
  /**
   * @type {boolean}
   * @memberof SupportUser
   */
  userActive?: boolean;
  /**
   * @type {string}
   * @memberof SupportUser
   */
  userEmail?: string;
  /**
   * @type {string}
   * @memberof SupportUser
   */
  userName?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum SupportUserUserAccessLevelsEnum {
  BUSINESS = 'BUSINESS',
  SUPPORT = 'SUPPORT',
  DEVELOPER = 'DEVELOPER',
  SALES = 'SALES',
  NOACCESS = 'NO_ACCESS'
}

<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M2,3 L2,16.1578111 L12.3921278,16.1578111 L17.0588235,19.787664 L17.0588235,16.1578111 L22,16.1578111 L22,3 L2,3 Z M1,1 L23,1 C23.5522847,1 24,1.44771525 24,2 L24,17.1578111 C24,17.7100958 23.5522847,18.1578111 23,18.1578111 L19.0588235,18.1578111 L19.0588235,21.8323727 C19.0588235,22.3846575 18.6111083,22.8323727 18.0588235,22.8323727 C17.8364385,22.8323727 17.6203982,22.7582444 17.4448618,22.6217085 L11.7058824,18.1578111 L1,18.1578111 C0.44771525,18.1578111 1.33903117e-14,17.7100958 1.33226763e-14,17.1578111 L0,2 C-6.76353751e-17,1.44771525 0.44771525,1 1,1 Z"
        style="fill: var(--v-secondary-base)"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { ExtendedGatewayState } from '../models';

export interface GetBsdbByGatewayIdUsingGETRequest {
  gatewayId: string;
}

export interface GetBsdbBySerialNumberUsingGETRequest {
  serialNumber: string;
}

/**
 * no description
 */
export class BsdbControllerApi extends BaseAPI {
  /**
   * getBsdbByGatewayId
   * Gets state of bsdb by gatewayId
   */
  getBsdbByGatewayIdUsingGET = (
    requestParameters: GetBsdbByGatewayIdUsingGETRequest
  ): Observable<ExtendedGatewayState> => {
    throwIfRequired(requestParameters, 'gatewayId', 'getBsdbByGatewayIdUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ExtendedGatewayState>({
      path: '/bsdb/{gatewayId}/state'.replace('{gatewayId}', encodeURI(requestParameters.gatewayId)),
      method: 'GET',
      headers
    });
  };

  /**
   * getBsdbBySerialNumber
   * Gets state of bsdb by serialNumber
   */
  getBsdbBySerialNumberUsingGET = (
    requestParameters: GetBsdbBySerialNumberUsingGETRequest
  ): Observable<ExtendedGatewayState> => {
    throwIfRequired(requestParameters, 'serialNumber', 'getBsdbBySerialNumberUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<ExtendedGatewayState>({
      path: '/bsdb/serialNumber/{serialNumber}/state'.replace(
        '{serialNumber}',
        encodeURI(requestParameters.serialNumber)
      ),
      method: 'GET',
      headers
    });
  };
}

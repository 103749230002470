// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface AccessCheckResponse
 */
export interface AccessCheckResponse {
  /**
   * @type {Array<string>}
   * @memberof AccessCheckResponse
   */
  accessLevels?: Array<string>;
  /**
   * @type {string}
   * @memberof AccessCheckResponse
   */
  context?: string;
  /**
   * @type {string}
   * @memberof AccessCheckResponse
   */
  role?: AccessCheckResponseRoleEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum AccessCheckResponseRoleEnum {
  ADMIN = 'ADMIN',
  TEMPADMIN = 'TEMPADMIN',
  USER = 'USER',
  ERRORNONLIEBHERRUSER = 'ERROR_NONLIEBHERRUSER',
  ERRORNOACCESS = 'ERROR_NOACCESS'
}

// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { VersionInformation } from '../models';

export interface GetCheckedServerVersionUsingGETRequest {
  frontendVersion: string;
}

/**
 * no description
 */
export class ConfigControllerApi extends BaseAPI {
  /**
   * getCheckedServerVersion
   * Gets the version of the server and sends the client version
   */
  getCheckedServerVersionUsingGET = (
    requestParameters: GetCheckedServerVersionUsingGETRequest
  ): Observable<VersionInformation> => {
    throwIfRequired(requestParameters, 'frontendVersion', 'getCheckedServerVersionUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<VersionInformation>({
      path: '/version/check/{frontendVersion}'.replace(
        '{frontendVersion}',
        encodeURI(requestParameters.frontendVersion)
      ),
      method: 'GET',
      headers
    });
  };
}

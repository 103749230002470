<template>
  <svg
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-951.000000, -481.000000)" fill="#171E3D" fill-rule="nonzero">
        <g transform="translate(951.000000, 481.000000)">
          <path
            d="M58.1818182,67.00436 L58.1818182,70 C58.1818182,71.6568542 56.8386724,73 55.1818182,73 L24.8181818,73 C23.1613276,73 21.8181818,71.6568542 21.8181818,70 L21.8181818,67.00436 C20.185957,66.5003005 19,64.9795569 19,63.1818182 L19,12 C19,9.790861 20.790861,8 23,8 L57,8 C59.209139,8 61,9.790861 61,12 L61,63.1818182 C61,64.9795569 59.814043,66.5003005 58.1818182,67.00436 L58.1818182,67.00436 Z M21,28 L59,28 L59,12 C59,10.8954305 58.1045695,10 57,10 L23,10 C21.8954305,10 21,10.8954305 21,12 L21,28 Z M21,30 L21,63.1818182 C21,64.2863877 21.8954305,65.1818182 23,65.1818182 L57,65.1818182 C58.1045695,65.1818182 59,64.2863877 59,63.1818182 L59,30 L21,30 Z M24.5454545,14 C24.5454545,13.4477153 24.9931698,13 25.5454545,13 C26.0977393,13 26.5454545,13.4477153 26.5454545,14 L26.5454545,24 C26.5454545,24.5522847 26.0977393,25 25.5454545,25 C24.9931698,25 24.5454545,24.5522847 24.5454545,24 L24.5454545,14 Z M24.5454545,34 C24.5454545,33.4477153 24.9931698,33 25.5454545,33 C26.0977393,33 26.5454545,33.4477153 26.5454545,34 L26.5454545,61 C26.5454545,61.5522847 26.0977393,62 25.5454545,62 C24.9931698,62 24.5454545,61.5522847 24.5454545,61 L24.5454545,34 Z M23.8181818,70 C23.8181818,70.5522847 24.2658971,71 24.8181818,71 L55.1818182,71 C55.7341029,71 56.1818182,70.5522847 56.1818182,70 L56.1818182,67.2727273 L23.8181818,67.2727273 L23.8181818,70 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

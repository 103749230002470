// tslint:disable
/**
 * SmartSupport Dashboard API
 * The backend service used by the Support application of SmartMonitoring
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfRequired, encodeURI } from '../runtime';
import { Message, MessageDescription } from '../models';

export interface CreateMessageUsingPOSTRequest {
  message: Message;
}

export interface DeleteMessagesUsingDELETERequest {
  requestBody: Array<string>;
}

export interface DownloadMessageUsingGETRequest {
  messagename: string;
}

export interface PublishMessageUsingPOSTRequest {
  messagename: string;
  body: string;
}

/**
 * no description
 */
export class MessagingControllerApi extends BaseAPI {
  /**
   * createMessage
   * Creates a new message.
   */
  createMessageUsingPOST = (requestParameters: CreateMessageUsingPOSTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'message', 'createMessageUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/messaging/create',
      method: 'POST',
      headers,
      body: requestParameters.message
    });
  };

  /**
   * deleteMessages
   * Deletes the message.
   */
  deleteMessagesUsingDELETE = (requestParameters: DeleteMessagesUsingDELETERequest): Observable<void> => {
    throwIfRequired(requestParameters, 'requestBody', 'deleteMessagesUsingDELETE');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<void>({
      path: '/messaging/delete',
      method: 'DELETE',
      headers,
      body: requestParameters.requestBody
    });
  };

  /**
   * downloadMessage
   * Gets the current messages for the user.
   */
  downloadMessageUsingGET = (requestParameters: DownloadMessageUsingGETRequest): Observable<Message> => {
    throwIfRequired(requestParameters, 'messagename', 'downloadMessageUsingGET');

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Message>({
      path: '/messaging/message/{messagename}'.replace('{messagename}', encodeURI(requestParameters.messagename)),
      method: 'GET',
      headers
    });
  };

  /**
   * hasWriteAccess
   * Checks whether the user has write access to messages
   */
  hasWriteAccessUsingGET = (): Observable<boolean> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/messaging/writeaccess',
      method: 'GET',
      headers
    });
  };

  /**
   * listMessages
   * Gets the current messages for the user.
   */
  listMessagesUsingGET = (): Observable<Array<MessageDescription>> => {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<Array<MessageDescription>>({
      path: '/messaging/list',
      method: 'GET',
      headers
    });
  };

  /**
   * publishMessage
   * Publishes a message.
   */
  publishMessageUsingPOST = (requestParameters: PublishMessageUsingPOSTRequest): Observable<boolean> => {
    throwIfRequired(requestParameters, 'messagename', 'publishMessageUsingPOST');
    throwIfRequired(requestParameters, 'body', 'publishMessageUsingPOST');

    const headers: HttpHeaders = {
      'Content-Type': 'application/json',
      ...(this.configuration.apiKey && { Authorization: this.configuration.apiKey('Authorization') }) // Authorization authentication
    };

    return this.request<boolean>({
      path: '/messaging/publish/{messagename}'.replace('{messagename}', encodeURI(requestParameters.messagename)),
      method: 'POST',
      headers,
      body: requestParameters.body as any
    });
  };
}
